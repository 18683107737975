import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from 'react';
import styled from 'styled-components';
import {message, Table, Select, Input} from 'antd';
import {errorHandler, ErrPromotion} from '../errors';
import {useOutlet} from 'reconnect.js';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import Row from '../Widgets/Row';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import BackButton from '../Components/BackButton';
const appConfig = require('../data.json');

const UISTATE = {
  LIST: 'LIST',
  DETAIL: 'DETAIL',
};

const PAGINATION = {
  limit: 10,
  current: 1,
};

function Detail({id, groups, onUpdate}) {
  const [record, _setRecord] = useState({
    code: '',
    usage: 0,
    discount: 0,
    name: '',
    threshold: 0,
    due_to: new Date().toISOString().slice(0, 10),
    user_group: undefined,
    user_usage_limit: 1,
  });
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const isEdit = !!id;

  const setRecord = (obj) => {
    _setRecord((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const getRecord = useCallback(async () => {
    setLoading(true);
    if (id) {
      try {
        let resp = await actions.getCoupon(id);
        _setRecord(resp);
      } catch (err) {
        errorHandler(err);
      }
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getRecord();
  }, [getRecord]);

  const valid = () => {
    if (!record.code) {
      throw new ErrPromotion('代碼不可為空白');
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      valid();
      if (isEdit) {
        await actions.editCoupon(record);
      } else {
        await actions.addCoupon(record);
      }
      message.success((isEdit ? '編輯' : '新增') + '成功');
      getRecord();
      onUpdate();
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  };

  return (
    <Block>
      <h2>{isEdit ? '編輯' : '新增'}促銷代碼</h2>
      <div className="divider" />
      <div>
        <div className="row">
          <h4>活動名稱</h4>
          <Input
            onChange={(e) => setRecord({name: e.target.value})}
            value={record.name}
          />
        </div>
        <div className="row">
          <h4>促銷代碼</h4>
          <Input
            onChange={(e) => setRecord({code: e.target.value})}
            value={record.code}
          />
        </div>
        <div className="row">
          <h4>折扣金額</h4>
          <Input
            type="number"
            onChange={(e) => setRecord({discount: parseInt(e.target.value)})}
            value={record.discount}
          />
        </div>

        <div className="row">
          <h4>全站數量限制</h4>
          <Input
            type="number"
            onChange={(e) => setRecord({usage: parseInt(e.target.value)})}
            value={record.usage}
          />
          <div style={{marginLeft: 10}}>
            已使用 {record.used_count || 0} 份 /{' '}
          </div>
          <div style={{marginLeft: 10}}>全站限量 {record.usage} 份</div>
        </div>

        <div className="row">
          <h4>會員次數限制</h4>
          <Input
            type="number"
            onChange={(e) =>
              setRecord({user_usage_limit: parseInt(e.target.value)})
            }
            value={record.user_usage_limit}
          />
          <div style={{marginLeft: 10}}>
            每位會員最多使用{record.user_usage_limit}次
          </div>
        </div>

        <div className="row">
          <h4>最低門檻</h4>
          <Input
            type="number"
            onChange={(e) => setRecord({threshold: parseInt(e.target.value)})}
            value={record.threshold}
          />
          <div style={{marginLeft: 10}}>
            訂單金額最少 {record.threshold} 元，可使用此優惠
          </div>
        </div>

        <div className="row">
          <h4>截止日期</h4>
          <Input
            type="date"
            onChange={(e) => setRecord({due_to: `${e.target.value}`})}
            value={record.due_to}
          />
        </div>

        <div className="row">
          <h4>會員群組</h4>
          <Select
            value={record.user_group}
            onChange={(value) => setRecord({user_group: value})}>
            <Select.Option value={undefined}>不指定群組</Select.Option>
            {groups.map((group, idx) => (
              <Select.Option key={idx} value={group.id}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="row" style={{margin: 0}}>
        <div style={{flex: 1}} />
        <Button onClick={submit} disabled={loading}>
          {isEdit ? '儲存' : '新增'}
        </Button>
      </div>
    </Block>
  );
}

export default function CouponPage(props) {
  const [uiState, setUiState] = useState(UISTATE.LIST);
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    ...PAGINATION,
  });
  const [selected, setSelected] = useState();
  const [groups, setGroups] = useState([]);

  function filtersChange(obj) {
    setFilters((prev) => ({...prev, ...obj}));
  }

  const getRecords = useCallback(async () => {
    setLoading(true);
    try {
      let resp = await actions.getCoupons({
        limit: filters.limit,
        offset: (filters.current - 1) * filters.limit,
        ordering: '-created',
      });

      setTotal(resp.count);
      setRecords(resp.results);
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  }, [filters]);

  const getGroups = useCallback(async () => {
    try {
      let resp = await actions.getUserGroups({});
      setGroups(resp);
    } catch (err) {
      errorHandler(err, '取得會員群組錯誤');
    }
  }, [actions]);

  useEffect(() => {
    getRecords();
    getGroups();
  }, [getRecords, getGroups]);

  if (uiState === UISTATE.DETAIL) {
    return (
      <Wrapper>
        <Row style={{marginBottom: 20}}>
          <BackButton
            onClick={() => {
              setSelected(null);
              setUiState(UISTATE.LIST);
            }}
          />
        </Row>

        <Detail id={selected?.id} groups={groups} onUpdate={getRecords} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div>
          <div className="row">
            <Button
              onClick={() => {
                setSelected(null);
                setUiState(UISTATE.DETAIL);
              }}>
              新增
            </Button>
            <div style={{flex: 1}} />
            <Button
              type="primary"
              onClick={getRecords}
              icon={
                <Refresh color="#fff" size={20} style={{marginRight: 5}} />
              }>
              刷新
            </Button>
          </div>
        </div>
      </Block>

      <Table
        loading={loading}
        rowKey="id"
        dataSource={records}
        columns={[
          {
            title: '編號',
            key: 'id',
            render: (record) => (
              <div
                style={{cursor: 'pointer', color: appConfig.colors.main}}
                onClick={() => {
                  setSelected(record);
                  setUiState(UISTATE.DETAIL);
                }}>
                {record.id}
              </div>
            ),
          },
          {
            title: '促銷代碼',
            dataIndex: 'code',
            key: 'code',
          },
          {
            title: '活動名稱',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '折價金額',
            dataIndex: 'discount',
            key: 'discount',
          },
          {
            title: '已使用數量',
            key: 'used_count',
            render: (record) => `${record.used_count} / ${record.usage}`,
          },
          {
            title: '截止日',
            dataIndex: 'due_to',
            key: 'due_to',
          },
          {
            title: '群組',
            dataIndex: 'user_group_name',
            key: 'user_group_name',
          },
        ]}
        onChange={(pagination) => filtersChange(pagination)}
        pagination={{
          total,
          pageSize: filters.limit,
          current: filters.current,
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
